import config from "@/config"
import http from "@/utils/request"

export default {
	Notify://临时通知
	{
		getNotify: {//临时通知查询
			url: `${config.API_URL}/Notify/GetNotify`,
			post: async function (params) {
				return await http.post(this.url, params)
			}
		},
	},
	VisitorManagement://访客管理
	{
		getVisitorManagement: {//访客管理查询
			url: `${config.API_URL}/Notify/GetVisitorManagement`,
			post: async function (params) {
				return await http.post(this.url, params)
			}
		},
	},
	//配置管理
	set:{
		menuSet:{
			url: `${config.API_URL}/Set/getGetMenuSet`,
			name: "配置管理/菜单配置",
			post: async function (params) {
				console.log(this.url)
				return await http.post(this.url, params);
			}
		},
		addMenu:{
			url: `${config.API_URL}/Set/addMenu`,
			name: "配置管理/菜单配置/添加菜单",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		editShowMenu:{
			url: `${config.API_URL}/Set/editShowMenu`,
			name: "配置管理/菜单配置/编辑菜单前显示数据",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		editMenu:{
			url: `${config.API_URL}/Set/editMenu`,
			name: "配置管理/菜单配置/编辑菜单",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		}
	},
	//系统管理
	system: {
		role2: {//用户页面或其他页面，调用角色，不显示禁用的角色
			url: `${config.API_URL}/Role/getRole2`,
			name: "系统管理/用户页面或其他页面，调用角色，不显示禁用的角色",
			post: async function (params) {
				return await http.post(this.url, params)
			}
		},
		department2: {//其他页面，获取部门管理，不显示禁用的部门
			url: `${config.API_URL}/Role/getDepartment2`,
			name: "系统管理/其他页面，获取部门管理，不显示禁用的部门",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		department: {//部门管理
			url: `${config.API_URL}/Department/getDepartment`,
			name: "系统管理/部门管理",
			post: async function (params) {
				console.log(await http.post(this.url, params))
				return await http.post(this.url, params);
			}
		},
		addDepartment: {
			url: `${config.API_URL}/Department/addDepartment`,
			name: "系统管理/部门管理/添加部门",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		editShowDepartment: {
			url: `${config.API_URL}/Department/editShowDepartment`,
			name: "系统管理/部门管理/编辑部门前显示数据",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		editDepartment: {
			url: `${config.API_URL}/Department/editDepartment`,
			name: "系统管理/部门管理/修改部门",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		role: {//角色管理
			url: `${config.API_URL}/Role/getRole`,
			name: "系统管理/角色管理",
			post: async function (params) {
				return await http.post(this.url, params)
			}
		},
		addRole: {
			url: `${config.API_URL}/Role/addRole`,
			name: "系统管理/角色管理/添加角色",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		editShowRole: {
			url: `${config.API_URL}/Role/editShowRole`,
			name: "系统管理/角色管理/编辑角色前显示数据",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		editRole: {
			url: `${config.API_URL}/Role/editRole`,
			name: "系统管理/角色管理/修改角色",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		user: {  //用户管理
			url: `${config.API_URL}/User/getUser`,
			name: "系统管理/用户管理",
			post: async function (params) {
				return await http.post(this.url, params)
			}
		},
		addUser: {
			url: `${config.API_URL}/User/addUser`,
			name: "系统管理/用户管理/添加用户",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		editShowUser: {
			url: `${config.API_URL}/User/editShowUser`,
			name: "系统管理/用户管理/编辑用户前显示数据",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		editUser: {
			url: `${config.API_URL}/User/editUser`,
			name: "系统管理/用户管理/修改用户",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},

	},
	
	ver: {
		url: `${config.API_URL}/demo/ver`,
		name: "获取最新版本号",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	post: {
		url: `${config.API_URL}/demo/post`,
		name: "分页列表",
		post: async function (data) {
			return await http.post(this.url, data, {
				headers: {
					//'response-status': 401
				}
			});
		}
	},
	page: {
		url: `${config.API_URL}/demo/page`,
		name: "分页列表",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	list: {
		url: `${config.API_URL}/demo/list`,
		name: "数据列表",
		get: async function (params) {
			return await http.get(this.url, params);
		}
	},
	menu: {
		url: `${config.API_URL}/demo/menu`,
		name: "普通用户菜单",
		get: async function () {
			return await http.get(this.url);
		}
	},
	status: {
		url: `${config.API_URL}/demo/status`,
		name: "模拟无权限",
		get: async function (code) {
			return await http.get(this.url, {}, {
				headers: {
					"response-status": code
				}
			});
		}
	}
}
